var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-item card tile tile-tv",class:[
    'uuid-' + _vm.uuid,
    {
      'is-past': _vm.liveStatus === 'PASADO',
      'is-future': _vm.liveStatus === 'FUTURO',
      'actual-show': _vm.liveStatus === 'PRESENTE',
    },
  ]},[(!_vm.showLoader)?[_c('ImageLazy',{attrs:{"src":_vm.poster,"err":_vm.posterError,"alt":_vm.title}}),_c('div',{staticClass:"card-channel"},[_c('span',{staticClass:"channel-logo",attrs:{"title":_vm.dataAsset.channel.name}},[_c('img',{staticClass:"img-logo",attrs:{"src":_vm.dataAsset.channel.logo,"alt":_vm.dataAsset.channel.name,"width":"40","height":"40"}})])]),(_vm.showProgress && _vm.liveStatus === 'PRESENTE' && !_vm.data.isFakeProgram)?_c('ProgressBarLive',{attrs:{"start":_vm.dataAsset.startHourUnixTime,"end":_vm.dataAsset.endHourUnixTime}}):_vm._e(),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-img-overlay"},[_c('div',{staticClass:"card-options-top"},[(_vm.showMoreInfo)?_c('a',{staticClass:"card-action-secondary btn-showmodal",attrs:{"href":"#more-info","title":"Más información"},on:{"click":function($event){$event.preventDefault();return _vm.onMoreInfo()}}},[_c('i',{staticClass:"zmdi zmdi-info-outline"})]):_vm._e(),(_vm.showChannelButton)?_c('router-link',{staticClass:"card-action-secondary",attrs:{"to":'/24-horas/' +
              _vm.dataAsset.channel.id +
              '/' +
              _vm.dataAsset.channel.name,"title":"Últimas 24 horas del canal"}},[_c('i',{staticClass:"zmdi zmdi-time-restore",on:{"click":function($event){return _vm.closeModal()}}})]):_vm._e()],1),(_vm.btnPlayState == 'pause-actual')?_c('a',{staticClass:"btn btn-play",attrs:{"href":"#play-this","title":"Pausar esto"},on:{"click":function($event){$event.preventDefault();_vm.getPlayer().onControlPlayPause()}}},[_c('i',{staticClass:"zmdi zmdi-pause",attrs:{"aria-hidden":"true"}})]):(_vm.btnPlayState == 'play-actual')?_c('a',{staticClass:"btn btn-play",attrs:{"href":"#play-this","title":"Reproducir esto"},on:{"click":function($event){$event.preventDefault();_vm.getPlayer().onControlPlayPause()}}},[_c('i',{staticClass:"zmdi zmdi-play",attrs:{"aria-hidden":"true"}})]):(_vm.btnPlayState != 'futuro')?_c('a',{staticClass:"btn btn-play",attrs:{"href":"#play-this","title":"Reproducir esto"},on:{"click":function($event){$event.preventDefault();return _vm.onPlay()}}},[_c('i',{staticClass:"zmdi zmdi-play",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.showMoreInfo)?_c('a',{staticClass:"more-info-all",attrs:{"href":"#more-info","title":"Más información"},on:{"click":function($event){$event.preventDefault();return _vm.onMoreInfo()}}}):_vm._e()])]),_c('div',{staticClass:"card-footer"},[(_vm.dataAsset.channel.lcn)?[_c('div',{staticClass:"card-tv-channel-number"},[_c('strong',{staticClass:"card-tv-channel-number-input"},[_vm._v("CH "+_vm._s(_vm.dataAsset.channel.lcn))])])]:_vm._e(),_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"card-text card-text--meta"},[(!_vm.data.isFakeProgram)?_c('span',{staticClass:"program-time"},[_vm._v(_vm._s(_vm.data.fechaInicio)+" de "+_vm._s(_vm.dataAsset.startHour)+" a "+_vm._s(_vm.dataAsset.endHour)+" hs.")]):_vm._e()])],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }