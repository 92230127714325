<template>
  <div
    class="slider-item card tile tile-tv"
    :class="[
      'uuid-' + uuid,
      {
        'is-past': liveStatus === 'PASADO',
        'is-future': liveStatus === 'FUTURO',
        'actual-show': liveStatus === 'PRESENTE',
      },
    ]"
  >
    <template v-if="!showLoader">
      <ImageLazy :src="poster" :err="posterError" :alt="title" />

      <div class="card-channel">
        <span class="channel-logo" :title="dataAsset.channel.name">
          <img
            :src="dataAsset.channel.logo"
            class="img-logo"
            :alt="dataAsset.channel.name"
            width="40"
            height="40"
          />
        </span>
      </div>

      <ProgressBarLive
        v-if="showProgress && liveStatus === 'PRESENTE' && !data.isFakeProgram"
        :start="dataAsset.startHourUnixTime"
        :end="dataAsset.endHourUnixTime"
      />

      <div class="card-content">
        <div class="card-img-overlay">
          <div class="card-options-top">
            <a
              v-if="showMoreInfo"
              v-on:click.prevent="onMoreInfo()"
              href="#more-info"
              class="card-action-secondary btn-showmodal"
              title="Más información"
            >
              <i class="zmdi zmdi-info-outline"></i>
            </a>

            <router-link
              v-if="showChannelButton"
              :to="
                '/24-horas/' +
                dataAsset.channel.id +
                '/' +
                dataAsset.channel.name
              "
              class="card-action-secondary"
              title="Últimas 24 horas del canal"
            >
              <i class="zmdi zmdi-time-restore" v-on:click="closeModal()"></i>
            </router-link>
          </div>

          <a
            v-if="btnPlayState == 'pause-actual'"
            v-on:click.prevent="getPlayer().onControlPlayPause()"
            href="#play-this"
            title="Pausar esto"
            class="btn btn-play"
          >
            <i class="zmdi zmdi-pause" aria-hidden="true"></i>
          </a>
          <a
            v-else-if="btnPlayState == 'play-actual'"
            v-on:click.prevent="getPlayer().onControlPlayPause()"
            href="#play-this"
            title="Reproducir esto"
            class="btn btn-play"
          >
            <i class="zmdi zmdi-play" aria-hidden="true"></i>
          </a>
          <a
            v-else-if="btnPlayState != 'futuro'"
            v-on:click.prevent="onPlay()"
            href="#play-this"
            title="Reproducir esto"
            class="btn btn-play"
          >
            <i class="zmdi zmdi-play" aria-hidden="true"></i>
          </a>

          <a
            v-if="showMoreInfo"
            v-on:click.prevent="onMoreInfo()"
            href="#more-info"
            class="more-info-all"
            title="Más información"
          ></a>
        </div>
      </div>

      <div class="card-footer">
        <template v-if="dataAsset.channel.lcn">
          <div class="card-tv-channel-number">
            <strong class="card-tv-channel-number-input"
              >CH {{ dataAsset.channel.lcn }}</strong
            >
          </div>
        </template>
        <h5 class="card-title">{{ title }}</h5>

        <p class="card-text card-text--meta">
          <span v-if="!data.isFakeProgram" class="program-time"
            >{{ data.fechaInicio }} de {{ dataAsset.startHour }} a
            {{ dataAsset.endHour }} hs.</span
          >
          <!-- <span v-if="showLive && dataAsset.status==='PRESENTE'" class="live-indicator">
                    <i class="zmdi zmdi-circle"></i>
                    <span class="pulse"></span>
                    en vivo
                </span> -->
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import IMG_NO_POSTER_16_9 from "@/assets/images/no-poster-16-9.png";
import { getStatus } from "@/observables/epg";
import { blockAsset } from "../helpers/parentalControl";

const IMG_DEFAULT_ALPHA =
  "a5434fa604af965aa91eb3e32c523ae2f383bfdf6f6cbaa5682ed36a60b5b33e";

export default {
  name: "cardTV",

  inject: ["getPlayer"],

  components: {
    ImageLazy: () => import("@/components/Image-lazy.vue"),
    ProgressBarLive: () => import("@/components/Progress-bar-live.vue"),
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    showLive: { type: Boolean, default: true },
    showChannelButton: { type: Boolean, default: true },
    showMoreInfo: {
      type: Boolean,
      required: false,
      default: true,
    },
    showProgress: {
      type: Boolean,
      required: false,
      default: true,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    zapflag: { type: Boolean, default: false },
    componentOrigin: {
      type: String,
      required: false,
      default: "",
    },
    sliderId: {
      type: String,
      default: "",
      required: false,
    },
    sliderTitle: {
      type: String,
      default: "",
      required: false,
    },
    cardId: {
      type: Number,
      default: 0,
      required: false,
    },
  },

  data() {
    return {
      btnPlayState: null,
      uuid: tplay.generateUUID(),
      blockByParentalControl: false,
      showLoader: true,
      dataAsset: null,
      oldValudeParentalControl: false,
      oldValueParentalCOntrol: false,
      cpChangedStatus: 1
    };
  },

  created() {
    this.updateDatosPrograma();
  },

  mounted() {
    if (this.data.isFakeProgram) {
      this.data.channel.id = this.data.channel.idChannel;
      this.data.channel.logo = this.data.channel.logoCanal;
    }

    //Escucho el cambio de estado del player
    this.$bus.$on("player:state-change", this.onPlayerStateChange);

    this.$bus.$on("parentalControl.state_changed", this.updateParentalControl);

    //Para que se inicialice el valor de btnPlayState
    this.onPlayerStateChange();

    // Control del control parental
    /*const edad = telecentro.tplay.core.biz.parentalControl.getAge();
        console.log('datos del asset',this.data);
        console.log('funcionamiento del interceptor', tplay.parentalInterceptor(this.data));*/
  },

  beforeDestroy() {
    this.$bus.$off("player:state-change", this.onPlayerStateChange);
    this.$bus.$off("parentalControl.state_changed", this.updateParentalControl);
  },

  methods: {
    updateParentalControl() {
      //console.log('parentalControl.state_changed',this.cpChangedStatus);
      this.cpChangedStatus++;
    },

    updateDatosPrograma() {
      const self = this;

      self.showLoader = true;
      self.dataAsset = self.data;

      self.blockByParentalControl = blockAsset(self.data);

      if (!self.search) {
        telecentro.tplay.core.epg.obtenerPrograma(
          self.data.channel.dvbTriplet,
          self.data.idAsset,
          function (asset) {
            self.dataAsset = asset;

            // Check moralityLevel
            self.blockByParentalControl = blockAsset(asset);

            self.onPlayerStateChange();

            self.showLoader = false;

            //onFinish();
          },
          function (err) {
            /* telecentro.tplay.core.epg.obtenerIdAssetEpg(this.data.title, this.data.startHourUnixTime, this.data.endHourUnixTime, this.data.channel.dvbTriplet, function(idAsset) {
                            console.log('El idAsset es ', idAsset)
                    })*/

            self.showLoader = false;
            //console.log("obtenerPrograma", err);
          }
        );
      } else {
        self.showLoader = false;
      }
    },

    /*getFullData: function(onFinish) {

            let self = this;

            telecentro.tplay.core.epg.obtenerPrograma(this.data.channel.dvbTriplet, this.data.idAsset, function(asset) {

                // Check moralityLevel
                self.blockByParentalControl = telecentro.tplay.core.biz.parentalControl.blockAsset(asset);

                onFinish();

            },function(err){
                console.log('obtenerPrograma',err);
            });
        },*/

    onMoreInfo: function () {
      let self = this;
      
      const { isActive = null, tplayPlayer = null } = this.getPlayer();
      if ( isActive && isActive() && tplayPlayer && tplayPlayer.mode == "full" ) return;

      tplay.sendGrafanaEvent(
        tplay.grafana.event.CARD,
        {
          rail_id: self.sliderId,
          rail_title: self.sliderTitle,
          card_id: self.cardId,
          screen: self.$route.name,
        },
        tplay.grafana.actions.ENTER
      );

      //this.getFullData(function() {

      if (blockAsset(self.data)) {
        self.$bus.$emit("modal-pin:show", function () {
          self.$bus.$emit("modal-tv:show", {
            data: self.dataAsset,
            componentOrigin: self.componentOrigin,
          });
        });
      } else {
        self.$bus.$emit("modal-tv:show", {
          data: self.dataAsset,
          componentOrigin: self.componentOrigin,
        });
      }

      //});
    },
    onPlay() {
      let self = this;

      tplay.sendGrafanaEvent(
        tplay.grafana.event.CARD,
        {
          rail_id: self.sliderId,
          rail_title: self.sliderTitle,
          card_id: self.cardId,
          screen: self.$route.path,
        },
        tplay.grafana.actions.ENTER
      );

      //this.getFullData(function() {

      if (blockAsset(self.data)) {
        self.$bus.$emit("modal-pin:show", function () {
          if (gtag) {
            gtag("event", "Reproducir desde card", {
              event_category: "CARD-TV",
              event_action: "Reproducir desde card",
              event_label:
                "[ " + self.dataAsset.channel.name + " ] " + self.title,
              value: "0",
            });
            // self.$bus.$emit('player:set-source', self.dataAsset);
          }

          self.$bus.$emit("player:set-source", self.dataAsset);
          tplay.sendAudience(self.dataAsset, self.componentOrigin);
        });
      } else {
        if (gtag) {
          gtag("event", "Reproducir desde card", {
            event_category: "CARD-TV",
            event_action: "Reproducir desde card",
            event_label:
              "[ " + self.dataAsset.channel.name + " ] " + self.title,
            value: "0",
          });
        }

        self.$bus.$emit("player:set-source", self.dataAsset);
        tplay.sendAudience(self.dataAsset, self.componentOrigin);
      }
      let butttonClose = document.querySelector(".close");
      butttonClose?.click();

      //},function(err){
      //    console.log('getFullData', err);
      //});
    },

    onPlayerStateChange() {
      if (getStatus(this.dataAsset) === "FUTURO") {
        this.btnPlayState = "futuro";
      } else if (
        this.getPlayer().isActive() &&
        this.getPlayer().isAsset(this.dataAsset.idAsset)
      ) {
        this.btnPlayState = this.getPlayer().isPlaying()
          ? "pause-actual"
          : "play-actual";
      } else {
        this.btnPlayState = "play-otro";
      }
    },

    closeModal() {
      hideModal(".modal-tv");
    },
  },

  computed: {
    liveStatus() {
      return getStatus(this.data);
    },

    poster() {
      const self = this;
      const urlFromAlpha = self.dataAsset.poster
        ? self.dataAsset.poster.split("/").pop()
        : "";
      
      self.cpChangedStatus;

      if (blockAsset(self.data) || urlFromAlpha === IMG_DEFAULT_ALPHA) {
        return IMG_NO_POSTER_16_9;
      } else {
        return self.dataAsset.poster
          ? self.dataAsset.poster
          : IMG_NO_POSTER_16_9;
      }
    },

    posterError() {
      return IMG_NO_POSTER_16_9;
    },

    title() {
      const self = this;
      self.cpChangedStatus;
      if (blockAsset(self.data)) {
        return "Programa bloqueado";
      } else {
        return telecentro.tplay.core.helpers.string.replaceUnicode(
          self.dataAsset.title.trim()
        );
      }
    },
  },

  watch: {
    "data.status": function (n, o) {
      if (n != 0) {
        this.updateDatosPrograma();
        //this.onPlayerStateChange();
      }
    },
    // blockByParentalControl:function(newValue, oldValue){
    //     // aca lo voy a guardar en forma de un objeto
    //     localStorage.setItem('oldvalueParentalControl',oldValue);
    //     localStorage.setItem('newvalueParentalControl',newValue);

    // },
    // chequeo si el zap esta activo y le asigno  a las cards del zap el valor del control parental que poseian antes de desbloqearlos
    // zapflag:function(newvalue, oldvalue){
    //     if (newvalue === true) {
    //         this.blockByParentalControl = localStorage.getItem('oldvalueParentalControl');
    //     }
    // }
  },
};
</script>

