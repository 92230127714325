import Vue from "vue";

/**
 * Temporizador que indica la expiración del código pin.
 * @private
 */
let timeOutPinCode = null;


/**
 * 
 * @param {*} asset 
 * @returns 
 */

function blockAsset(asset){
    if(asset){
        return telecentro.tplay.core.biz.parentalControl.blockAsset(asset);
    } else {
        return false;
    }
}

/**
 * Crea el temporizador para el recordatorio del pin.
 */
function setTimeOutParentalControl() {
    // 15 minutos
    // 30 minutos
    // 0 hasta salir del sitio (no nos interesa esta opción por ahora)
    // -1 nunca
    const rememberMinutes = window.telecentro.tplay.core.biz.parentalControl.getRemember() * 1;
    let msToExpired = 0;

    clearTimeout(timeOutPinCode);

    if(rememberMinutes < 1){
        Vue.prototype.$bus.$emit('parentalControl.state_changed');
        return;
    }

    msToExpired = rememberMinutes * 60 * 1000;

	if (msToExpired > 0) {
        //console.log('[ CP ] setTimeOutParentalControl activo');
        Vue.prototype.$bus.$emit('parentalControl.state_changed');
		timeOutPinCode = setTimeout(() => {
			Vue.prototype.$bus.$emit('parentalControl.state_changed');
		}, msToExpired);
	}
}

export {
    blockAsset,
    setTimeOutParentalControl
}